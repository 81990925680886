///////////////////////////////////////////////////////////
// File        : SignIn.jsx
// Description : 

// Imports : 

import React from "react";
import { GoogleLogin } from 'react-google-login';

import "./SignIn.scss";
import { onLogin } from "~/Components/api/google";
import { clientId } from "~/googleClientId";

// Function Definition
function SignIn(props) {
  // console.info("SignIn.render");
  return (
    <div className="signIn">
      <GoogleLogin
        clientId={clientId}
        onSuccess={onLogin}
        onFailure={onLogin}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

// Exports

export default SignIn;

